import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useDisclosure,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { BiSolidHide, BiSolidShow } from "react-icons/bi";
import { FC } from "react";
import { AccountState, AccountUser, signUp } from "@/app/actions/auth";
import { Values } from "@/components/SignUpForm/types";
import { useRecaptcha } from "@/hooks/recaptcha/useRecaptcha";

type CreateUserProps = {
  onSuccess: (user: AccountUser) => void;
  isComplete: boolean;
};

const CreateUser: FC<CreateUserProps> = ({ onSuccess, isComplete }) => {
  const { withRecaptchaToken } = useRecaptcha<
    Values["user"],
    Promise<AccountState>
  >();
  const {
    register,
    setError,
    clearErrors,
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = useFormContext<Values>();
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Grid
      gap={4}
      gridTemplateColumns="auto auto"
      role="form"
      as="form"
      onSubmit={handleSubmit(async (values) => {
        const state = await withRecaptchaToken(signUp)(values.user);

        if (state?.error) {
          setError("root.serverError", {
            type: state.error.code,
            message: state.error.description,
          });
        } else {
          clearErrors("root.serverError");
        }

        if (state?.data) {
          onSuccess(state.data);
        }
      })}
    >
      <FormControl isDisabled={isComplete} isRequired>
        <FormLabel>First name</FormLabel>
        <Input
          autoFocus
          {...register("user.firstname", {
            required: true,
          })}
        />
      </FormControl>
      <FormControl isDisabled={isComplete}>
        <FormLabel>Last name</FormLabel>
        <Input {...register("user.lastname")} />
      </FormControl>
      <GridItem colSpan={2}>
        <FormControl isDisabled={isComplete} isRequired>
          <FormHelperText>
            Verify your Account via SMS for a secure sign up
          </FormHelperText>
          <FormLabel>Mobile phone number</FormLabel>
          <Input
            type="tel"
            {...register("user.phone", {
              required: true,
            })}
          />
        </FormControl>
      </GridItem>
      <GridItem colSpan={2}>
        <FormControl isDisabled={isComplete} isRequired>
          <FormLabel>Email</FormLabel>
          <Input
            {...register("user.email", {
              required: true,
            })}
          />
        </FormControl>
      </GridItem>
      <GridItem colSpan={2}>
        <FormControl isDisabled={isComplete} isRequired>
          <FormLabel>Password</FormLabel>
          <InputGroup size="md">
            <Input
              {...register("user.password", {
                required: true,
              })}
              type={isOpen ? "text" : "password"}
              placeholder="Enter password"
            />
            <InputRightElement>
              <IconButton
                variant="unstyled"
                aria-label="Toggle password visibility"
                onClick={onToggle}
                icon={<Icon as={isOpen ? BiSolidHide : BiSolidShow} />}
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>
      </GridItem>
      <GridItem colSpan={2}>
        <Button
          w="100%"
          isDisabled={!isDirty || isComplete}
          isLoading={isSubmitting}
          type="submit"
          colorScheme="midnight_navy"
        >
          Next
        </Button>
      </GridItem>
    </Grid>
  );
};

export default CreateUser;
