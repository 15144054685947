import { useBoolean } from "@chakra-ui/react";
import { useRef, useEffect } from "react";

type Options = IntersectionObserverInit & {
  once?: boolean;
};

const DEFAULT_OPTIONS: Options = {
  root: null,
  rootMargin: "0px",
  threshold: 0,
  once: false,
};

export const useVisible = (props?: Partial<Options>) => {
  const options = { ...DEFAULT_OPTIONS, ...props };
  const { once, ...intersectionOptions } = options;
  const [visible, { on, off }] = useBoolean(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const element = elementRef.current;
    if (!element) return;

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        on();
        // Only disconnect if once is true
        if (once) {
          observer.disconnect();
        }
      } else if (!once) {
        // Only track false state if once is false
        off();
      }
    }, intersectionOptions);

    observer.observe(element);

    return () => observer.disconnect();
  }, [intersectionOptions, on, off, once]);

  return { elementRef, visible };
};
