import { FC } from "react";
import {
  Heading,
  HStack,
  PinInput,
  PinInputField,
  VStack,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { Values } from "@/components/SignUpForm/types";
import { AccountState, AccountUser, verify } from "@/app/actions/auth";
import ResendVerificationCode from "@/components/SignUpForm/ResendVerificationCode";
import { useBoolean } from "@chakra-ui/icons";
import { useRecaptcha } from "@/hooks/recaptcha/useRecaptcha";

type ConfirmPhoneNumberProps = {
  onSuccess: (accountUser: AccountUser) => void;
  isComplete: boolean;
};

const ConfirmPhoneNumber: FC<ConfirmPhoneNumberProps> = ({
  onSuccess,
  isComplete,
}) => {
  const {
    watch,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useFormContext<Values>();

  const [phone, pin] = watch(["user.phone", "pin"]);
  const [canResend, { on }] = useBoolean(false);
  const { withRecaptchaToken } = useRecaptcha<
    { phone: string; verificationCode: string },
    Promise<AccountState>
  >();

  return (
    <VStack spacing={4}>
      <Heading size="md">
        Enter the verification code we’ve sent to {phone}
      </Heading>
      <HStack>
        <PinInput
          placeholder=""
          value={pin}
          onChange={(value) => {
            setValue("pin", value);
          }}
          isInvalid={Boolean(errors.pin)}
          isDisabled={isSubmitting || isComplete}
          onComplete={(value) =>
            handleSubmit(async () => {
              const state = await withRecaptchaToken(verify)({
                phone,
                verificationCode: value,
              });
              if (state?.error) {
                setError("root.serverError", {
                  type: state.error.code,
                  message: state.error.description,
                });
                on();
              }

              if (state?.data) {
                clearErrors("root.serverError");
                onSuccess(state.data);
              }
            })()
          }
          autoFocus
          size="lg"
        >
          <PinInputField aria-label={"Pin code #1"} />
          <PinInputField aria-label={"Pin code #2"} />
          <PinInputField aria-label={"Pin code #3"} />
          <PinInputField aria-label={"Pin code #4"} />
        </PinInput>
      </HStack>
      {canResend && (
        <ResendVerificationCode
          onResend={() => {
            setValue("pin", "");
            clearErrors("root.serverError");
          }}
        />
      )}
    </VStack>
  );
};

export default ConfirmPhoneNumber;
