"use client";
import { FC, useCallback, useEffect, useRef } from "react";
import BannerListSlider from "@/components/Homepage/BannerList";
import ProductList from "@/components/ProductList";
import { DeepPartial } from "@apollo/client/utilities";
import { HomePageUnion } from "@/documents/__generated__/globalTypes.codegen";
import isDefined from "@/utils/isDefined";
import {
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { EventAction, EventSource } from "@/app/lib/analytics/types";
import { useHomepageEvent } from "@/hooks/analytics/trackers/useHomepageEvent";
import { useVisible } from "@/hooks/visibility/useVisible";
import { useReactiveVar } from "@apollo/client";
import { signUpCelebrationReactiveVar } from "@/components/SignUpForm";
import { useDisclosure } from "@chakra-ui/hooks";
import Image from "next/image";
import { useBoolean } from "@chakra-ui/icons";

type HomepageContainerProps = {
  components: DeepPartial<HomePageUnion>[];
};

type HomepageComponentProps = {
  component: DeepPartial<HomePageUnion>;
  position: number;
};

const HomepageComponent: FC<HomepageComponentProps> = ({
  component,
  position,
}) => {
  const { trackHomepage } = useHomepageEvent({
    component_id: component.id,
    component_type: component.__typename,
    component_priority: component.priority,
    component_title: component.title,
    component_position: position,
  });
  const { elementRef: boxRef, visible: onceVisible } = useVisible({
    once: true,
  });
  const { elementRef: bannerRef, visible } = useVisible();
  const scrolledRef = useRef(false);

  useEffect(() => {
    if (onceVisible) {
      trackHomepage(EventAction.COMPONENT_VISIBLE);
    }
  }, [onceVisible]);

  const onViewAll = useCallback(() => {
    trackHomepage(EventAction.COMPONENT_TAPPED_VIEW_ALL);
  }, []);

  const onceScrolled = useCallback(() => {
    if (!scrolledRef.current) {
      trackHomepage(EventAction.COMPONENT_HORIZONTALLY_SCROLLED);
      scrolledRef.current = true;
    }
  }, []);

  return (
    <Box ref={boxRef}>
      {component.__typename === "BannerListComponent" && (
        <BannerListSlider
          {...component}
          ref={bannerRef}
          onClick={(banner) => {
            trackHomepage(EventAction.COMPONENT_BANNER_TAPPED, {
              banner_name: banner.name,
              source_object: EventSource.HOME,
              id: banner.id,
              position: position,
              text: banner.frontText,
              cta_text: banner.ctaText,
            });
          }}
          onShow={(currentSlide) => {
            if (visible) {
              const banner =
                component.banners?.nodes?.filter(isDefined)[currentSlide];

              trackHomepage(EventAction.COMPONENT_BANNER_DISPLAYED, {
                banner_name: banner?.name,
                source_object: EventSource.HOME,
                id: banner?.id,
                position: currentSlide,
                text: banner?.frontText,
                cta_text: banner?.ctaText,
              });
            }
          }}
        />
      )}

      {(component.__typename === "SalesRepresentativeComponent" ||
        component.__typename === "ProductListComponent") && (
        <ProductList
          {...component}
          onViewAll={onViewAll}
          products={component.products?.nodes?.filter(isDefined)}
          variant="carousel"
          onAfterChange={onceScrolled}
          cellMetadata={{
            source_object: EventSource.HOME,
            sourceComponentId: component.id,
          }}
        />
      )}
    </Box>
  );
};

const HomepageContainer: FC<HomepageContainerProps> = ({ components }) => {
  const celebrate = useReactiveVar(signUpCelebrationReactiveVar);
  const { isOpen, onClose } = useDisclosure({
    defaultIsOpen: celebrate,
  });
  const [loaded, { on }] = useBoolean();
  const [animated, { on: ended }] = useBoolean();
  useEffect(() => {
    if (loaded) {
      setTimeout(ended, 3000);
    }
  }, [loaded]);
  return (
    <>
      <Modal
        size="md"
        isOpen={isOpen}
        onClose={onClose}
        onCloseComplete={() => signUpCelebrationReactiveVar(false)}
      >
        <ModalOverlay />
        <ModalContent aria-label={"Welcome to Kimelo!"}>
          <ModalHeader>Welcome to Kimelo!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack>
              <Image
                priority
                onLoad={on}
                src={animated ? "/img/last_frame.png" : "/img/animation.webp"}
                width={250}
                height={200}
                alt="Welcome to Kimelo!"
              />
            </VStack>
          </ModalBody>

          <ModalFooter>
            <ButtonGroup>
              <Button
                colorScheme="midnight_navy"
                aria-label="Close"
                onClick={onClose}
              >
                Start shopping
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <VStack align="stretch" spacing={6}>
        {components.map((component, position) => (
          <HomepageComponent
            component={component}
            position={position}
            key={component.id}
          />
        ))}
      </VStack>
    </>
  );
};

export default HomepageContainer;
