"use client";
import { FC, useActionState, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Values } from "@/components/SignUpForm/types";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
} from "@chakra-ui/react";
import { PlacesAutocompleteInput } from "@/components/PlacesAutocompleteInput";
import { parseAddress } from "@/components/PlacesAutocompleteInput/utils";
import { addRestaurant, UserState } from "@/app/actions/auth";
import { SessionUser } from "@/hooks/user/utils";
import dynamic from "next/dynamic";
import { submitFormDataWithTransition } from "@/app/lib/actions";
import { ErrorBoundary } from "react-error-boundary";

const CuisineTypeOptions = dynamic(() => import("../CuisineTypeOptions"), {
  ssr: false,
});

export type StateOption = {
  value: string;
  shorthand: string;
};

export const STATES: StateOption[] = [
  { value: "Alabama", shorthand: "AL" },
  { value: "Alaska", shorthand: "AK" },
  { value: "American Samoa", shorthand: "AS" },
  { value: "Arizona", shorthand: "AZ" },
  { value: "Arkansas", shorthand: "AR" },
  { value: "California", shorthand: "CA" },
  { value: "Colorado", shorthand: "CO" },
  { value: "Connecticut", shorthand: "CT" },
  { value: "Delaware", shorthand: "DE" },
  { value: "District Of Columbia", shorthand: "DC" },
  { value: "Federated States Of Micronesia", shorthand: "FM" },
  { value: "Florida", shorthand: "FL" },
  { value: "Georgia", shorthand: "GA" },
  { value: "Guam", shorthand: "GU" },
  { value: "Hawaii", shorthand: "HI" },
  { value: "Idaho", shorthand: "ID" },
  { value: "Illinois", shorthand: "IL" },
  { value: "Indiana", shorthand: "IN" },
  { value: "Iowa", shorthand: "IA" },
  { value: "Kansas", shorthand: "KS" },
  { value: "Kentucky", shorthand: "KY" },
  { value: "Louisiana", shorthand: "LA" },
  { value: "Maine", shorthand: "ME" },
  { value: "Marshall Islands", shorthand: "MH" },
  { value: "Maryland", shorthand: "MD" },
  { value: "Massachusetts", shorthand: "MA" },
  { value: "Michigan", shorthand: "MI" },
  { value: "Minnesota", shorthand: "MN" },
  { value: "Mississippi", shorthand: "MS" },
  { value: "Missouri", shorthand: "MO" },
  { value: "Montana", shorthand: "MT" },
  { value: "Nebraska", shorthand: "NE" },
  { value: "Nevada", shorthand: "NV" },
  { value: "New Hampshire", shorthand: "NH" },
  { value: "New Jersey", shorthand: "NJ" },
  { value: "New Mexico", shorthand: "NM" },
  { value: "New York", shorthand: "NY" },
  { value: "North Carolina", shorthand: "NC" },
  { value: "North Dakota", shorthand: "ND" },
  { value: "Northern Mariana Islands", shorthand: "MP" },
  { value: "Ohio", shorthand: "OH" },
  { value: "Oklahoma", shorthand: "OK" },
  { value: "Oregon", shorthand: "OR" },
  { value: "Palau", shorthand: "PW" },
  { value: "Pennsylvania", shorthand: "PA" },
  { value: "Puerto Rico", shorthand: "PR" },
  { value: "Rhode Island", shorthand: "RI" },
  { value: "South Carolina", shorthand: "SC" },
  { value: "South Dakota", shorthand: "SD" },
  { value: "Tennessee", shorthand: "TN" },
  { value: "Texas", shorthand: "TX" },
  { value: "Utah", shorthand: "UT" },
  { value: "Vermont", shorthand: "VT" },
  { value: "Virgin Islands", shorthand: "VI" },
  { value: "Virginia", shorthand: "VA" },
  { value: "Washington", shorthand: "WA" },
  { value: "West Virginia", shorthand: "WV" },
  { value: "Wisconsin", shorthand: "WI" },
  { value: "Wyoming", shorthand: "WY" },
];

type CreateRestaurantProps = {
  onSuccess: (user: SessionUser) => void;
  isComplete: boolean;
};

const CreateRestaurant: FC<CreateRestaurantProps> = ({
  onSuccess,
  isComplete,
}) => {
  const {
    register,
    control,
    setError,
    reset,
    watch,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useFormContext<Values>();
  const [state, action, pending] = useActionState<UserState, FormData>(
    addRestaurant,
    null,
  );
  const [complete, user, pin] = watch(["complete", "user", "pin"]);

  useEffect(() => {
    if (state?.error) {
      setError("root.serverError", {
        type: state.error.code,
        message: state.error.description,
      });
    }

    if (state?.data) {
      onSuccess(state?.data);
      clearErrors("root.serverError");
    }
  }, [state]);

  return (
    <form
      role={"form"}
      onSubmit={(e) => {
        return submitFormDataWithTransition({
          e,
          action,
          handleSubmit,
        });
      }}
    >
      <Grid gridTemplateColumns="1fr 1fr 1fr" gap={4}>
        <GridItem colSpan={3}>
          <FormControl isRequired isInvalid={Boolean(errors.restaurant?.name)}>
            <FormLabel>Business name</FormLabel>
            <Controller
              rules={{ required: "Field is required" }}
              render={({ field }) => {
                return (
                  <PlacesAutocompleteInput
                    {...field}
                    isInvalid={Boolean(errors.restaurant?.name)}
                    isRequired
                    onSelectPlace={(suggestion, placeResult) => {
                      if (placeResult) {
                        const address = parseAddress(placeResult);

                        reset({
                          complete,
                          user,
                          pin,
                          restaurant: {
                            name: suggestion.title,
                            address:
                              `${address.houseNumber} ${address.street}`.trim(),
                            city: address.city,
                            state: address.state,
                            zipcode: address.postalCode,
                          },
                        });
                      }
                    }}
                  />
                );
              }}
              name="restaurant.name"
              control={control}
            />
            {errors.restaurant?.name && (
              <FormErrorMessage>
                {errors.restaurant?.name.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem colSpan={3}>
          <FormControl isRequired>
            <FormLabel>Street address</FormLabel>
            <Input {...register("restaurant.address")} />
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isRequired>
            <FormLabel>City</FormLabel>
            <Input {...register("restaurant.city")} />
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isRequired>
            <FormLabel>State</FormLabel>
            <Select
              {...register("restaurant.state")}
              placeholder="Select state"
            >
              {STATES.map((state) => (
                <option key={state.value} value={state.shorthand}>
                  {state.value}
                </option>
              ))}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isRequired>
            <FormLabel>Zipcode</FormLabel>
            <Input {...register("restaurant.zipcode")} />
          </FormControl>
        </GridItem>
        <GridItem colSpan={3}>
          <FormControl>
            <FormLabel>Cuisine type</FormLabel>
            <Select
              {...register("restaurant.cuisineType")}
              placeholder="Choose cuisine type"
            >
              <ErrorBoundary fallback={null}>
                <CuisineTypeOptions />
              </ErrorBoundary>
            </Select>
          </FormControl>
        </GridItem>
        <GridItem colSpan={3}>
          <Button
            type="submit"
            isLoading={pending || isComplete}
            w="100%"
            colorScheme="midnight_navy"
          >
            Complete
          </Button>
        </GridItem>
      </Grid>
    </form>
  );
};

export default CreateRestaurant;
