"use client";
import { useBoolean } from "@chakra-ui/react";
import { useCallback, useContext, useEffect } from "react";
import { RecaptchaContext } from "@/app/RecaptchaProvider";

const recaptchaRequestBodyFieldName = "g-recaptcha-response-data" as const;

export const useRecaptcha = <T, K>() => {
  const [ready, { on }] = useBoolean();
  const recaptcha = useContext(RecaptchaContext);

  useEffect(() => {
    if (recaptcha) {
      recaptcha.ready(on);
    }
  }, [on, recaptcha]);

  const withRecaptchaToken = useCallback(
    (
      fn: (data: T | (T & { [recaptchaRequestBodyFieldName]: string })) => K,
    ) => {
      const siteKey = process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY;

      if (siteKey) {
        return async function (data: T) {
          let token;
          try {
            token = await recaptcha?.execute(siteKey);
          } catch (e) {
            console.error(`Recaptcha error: ${e}`);
          }

          return fn({
            ...data,
            ...(token && {
              [recaptchaRequestBodyFieldName]: token,
            }),
          });
        };
      } else {
        return function (data: T) {
          return fn(data);
        };
      }
    },
    [recaptcha],
  );
  return {
    ready,
    withRecaptchaToken,
  };
};
