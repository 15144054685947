import { FormEvent, startTransition } from "react";
import { FieldValues, UseFormHandleSubmit } from "react-hook-form";

export function submitFormDataWithTransition<T extends FieldValues>({
  e,
  action,
  handleSubmit,
}: {
  e: FormEvent<HTMLFormElement>;
  action: (payload: FormData) => void;
  handleSubmit: UseFormHandleSubmit<T>;
}) {
  const form = e.target as HTMLFormElement;
  return handleSubmit(() => {
    return startTransition(() => action(new FormData(form)));
  })(e);
}
