import useAnalytics from "@/hooks/analytics";
import {
  BannerEventMetadata,
  EventName,
  HomepageEventMetadata,
  HomepageEventProperties,
} from "@/hooks/analytics/types";
import { useCallback } from "react";
import { EventAction, EventObject } from "@/app/lib/analytics/types";

export const useHomepageEvent = (metadata: HomepageEventMetadata) => {
  const { track } = useAnalytics<HomepageEventProperties>();

  const trackHomepage = useCallback(
    (action: EventAction, bannerMetadata?: BannerEventMetadata) => {
      const eventName: EventName = `${EventObject.HOME} ${action}`;
      return track(eventName, {
        ...metadata,
        ...bannerMetadata,
      });
    },
    [metadata, track],
  );

  return {
    trackHomepage,
  };
};
