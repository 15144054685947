"use client";
import { createContext, FC, PropsWithChildren, useState } from "react";
import Script from "next/script";

export const RecaptchaContext = createContext<typeof window.grecaptcha | null>(
  null,
);

const RecaptchaProvider: FC<PropsWithChildren> = ({ children }) => {
  const [recaptcha, setRecaptcha] = useState<typeof window.grecaptcha | null>(
    null,
  );

  return (
    <RecaptchaContext.Provider value={recaptcha}>
      <Script
        onReady={() => {
          setRecaptcha(window.grecaptcha);
        }}
        src={`https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY}`}
      />
      {children}
    </RecaptchaContext.Provider>
  );
};

export default RecaptchaProvider;
