import { FC, startTransition, useActionState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Button } from "@chakra-ui/react";
import {
  AccountState,
  Resend,
  resendVerificationCode,
} from "@/app/actions/auth";
import { useBoolean } from "@chakra-ui/icons";

const RESEND_TIMEOUT = 60_000;

type ResendVerificationCodeProps = {
  onResend: () => void;
};

const ResendVerificationCode: FC<ResendVerificationCodeProps> = ({
  onResend,
}) => {
  const { getValues } = useFormContext();
  const [isDisabled, { on, off }] = useBoolean(false);
  const [state, action, pending] = useActionState<AccountState, Resend>(
    resendVerificationCode,
    null,
  );

  useEffect(() => {
    if (state?.data) {
      onResend();
    }
  }, [state?.data]);
  return (
    <Button
      size="sm"
      isLoading={pending}
      onClick={() => {
        return startTransition(() => {
          on();
          setTimeout(off, RESEND_TIMEOUT);
          return action({ phone: getValues("user.phone") });
        });
      }}
      isDisabled={isDisabled}
    >
      ResendVerificationCode
    </Button>
  );
};

export default ResendVerificationCode;
