"use client";
import React, {
  useMemo,
  Fragment,
  ForwardRefRenderFunction,
  forwardRef,
} from "react";
import isDefined from "@/utils/isDefined";
import Image from "next/image";
import {
  Banner,
  BannerListComponent,
} from "@/documents/__generated__/globalTypes.codegen";
import { DeepPartial } from "@apollo/client/utilities";
import Slider from "react-slick";
import { parseBanner } from "@/hooks/banners/utils";
import { Box } from "@chakra-ui/react";
import { Link } from "@chakra-ui/next-js";

type BannerListProps = DeepPartial<BannerListComponent> & {
  onShow?: (currentSlide: number) => void;
  onClick?: (banner: DeepPartial<Banner>) => void;
};

const BannerListView: ForwardRefRenderFunction<
  HTMLDivElement,
  BannerListProps
> = ({ banners, onShow, onClick }, ref) => {
  const { items, isSlider } = useMemo(() => {
    const items = banners?.nodes?.filter(isDefined);
    const length = banners?.nodes?.filter(isDefined).length || 0;
    return {
      isSlider: length > 1,
      items,
    };
  }, [banners]);

  if (!items?.length) return null;

  return (
    <Box className="h-14 md:h-24 xl:h-40 lg:h-32 w-full mb-4" ref={ref}>
      <Box
        as={Fragment}
        {...(isSlider && {
          as: Slider,
          dots: true,
          autoplay: true,
          slidesToShow: 1,
          arrows: false,
          autoplaySpeed: 5000,
          onInit: () => {
            onShow?.(0);
          },
          afterChange: onShow,
        })}
      >
        {items?.map((banner) => {
          const { id, version, frontText, link } = parseBanner(banner);
          return (
            <Box key={id}>
              <Link
                href={link}
                target="_blank"
                prefetch={false}
                onClick={() => {
                  onClick?.(banner);
                }}
                className="block h-14 md:h-24 xl:h-40 lg:h-32 relative"
              >
                {version.imageUrl && (
                  <Image
                    alt={frontText}
                    src={version.imageUrl}
                    fill
                    className="object-contain"
                    sizes="auto"
                  />
                )}
              </Link>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

const BannerList = forwardRef(BannerListView);

export default BannerList;
