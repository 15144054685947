export type Address = {
  placeId: string;
  country: string;
  state: string;
  city: string;
  street: string;
  houseNumber?: number;
  postalCode: string;
  latLng: {
    lat: number;
    lng: number;
  };
};

const addressComponentsMap = {
  country: ["country"],
  state: [
    "administrative_area_level_1",
    "administrative_area_level_2",
    "administrative_area_level_3",
    "administrative_area_level_4",
    "administrative_area_level_5",
  ],
  city: [
    "locality",
    "sublocality",
    "sublocality_level_1",
    "sublocality_level_2",
    "sublocality_level_3",
    "sublocality_level_4",
  ],
  street: ["street_address", "route"],
  houseNumber: ["street_number"],
  postalCode: ["postal_code"],
};

export const THE_US_GEOGRAPHIC_CENTER: google.maps.LatLngLiteral = {
  lat: 39.8097343,
  lng: -98.5556204,
};

const addressTemplate: Address = Object.freeze({
  country: "",
  state: "",
  city: "",
  street: "",
  placeId: "",
  postalCode: "",
  latLng: THE_US_GEOGRAPHIC_CENTER,
});

const assignAddressComponent = (
  address: Address,
  component: google.maps.GeocoderAddressComponent,
  [addressKey, components]: [keyof Address, string[]],
) => {
  if (components.indexOf(component.types[0]) !== -1) {
    switch (addressKey) {
      case "state":
      case "country":
        address[addressKey] = component.short_name;
        break;
      case "city":
      case "street":
      case "postalCode":
        address[addressKey] = component.long_name;
        break;
      case "houseNumber":
        address[addressKey] = Number(component.long_name);
    }
  }
};

const assignAddressComponents = (
  address: Address,
  component: google.maps.GeocoderAddressComponent,
) => {
  Object.entries(addressComponentsMap).forEach(
    ([addressKey, components]: [string, string[]]) =>
      assignAddressComponent(address, component, [
        addressKey as keyof Address,
        components,
      ]),
  );
};

export const parseAddress = (placeResult: google.maps.places.PlaceResult) => {
  const address: Address = {
    ...addressTemplate,
  };

  if (placeResult.place_id) {
    address.placeId = placeResult.place_id;
  }

  if (!placeResult.address_components) {
    return address;
  }

  if (placeResult.geometry?.location) {
    address.latLng = {
      lat: placeResult.geometry.location.lat(),
      lng: placeResult.geometry.location.lng(),
    };
  }

  placeResult.address_components.forEach((component) =>
    assignAddressComponents(address, component),
  );

  return address;
};
