"use client";
import Script from "next/script";
import {
  ChangeEventHandler,
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
  useCallback,
  useState,
} from "react";
import AutocompleteService = google.maps.places.AutocompleteService;
import { useBoolean } from "@chakra-ui/icons";
import SearchAutocomplete, { ControlProps } from "@/components/Autocomplete";
import PlacesService = google.maps.places.PlacesService;
import {
  UseComboboxGetInputPropsOptions,
  UseComboboxSelectedItemChange,
} from "downshift";
import { Box, InputProps, VStack, Text } from "@chakra-ui/react";

type AutocompleteSuggestion = {
  id: google.maps.places.AutocompletePrediction["place_id"];
  title: google.maps.places.StructuredFormatting["main_text"];
  subtitle: google.maps.places.StructuredFormatting["secondary_text"];
};

const getDefaultSearchOptions = () => ({
  // NOTE: same bounds as iOS/Android apps use
  bounds: new google.maps.LatLngBounds(
    new google.maps.LatLng(37.020969, -123.304144),
    new google.maps.LatLng(38.605295, -121.064172),
  ),
  componentRestrictions: {
    country: "us",
  },
  types: ["food"],
});

export type WrappedPlacesAutocompleteInputViewProps = {
  onSelectPlace: (
    suggestion: AutocompleteSuggestion,
    placeResult: google.maps.places.PlaceResult | null,
  ) => void;
  request?: Partial<google.maps.places.AutocompletionRequest>;
  renderItem?: (item: AutocompleteSuggestion) => ReactNode;
} & Omit<UseComboboxGetInputPropsOptions, "size"> &
  ControlProps<InputProps>;

export const WrappedPlacesAutocompleteInputView: ForwardRefRenderFunction<
  HTMLInputElement,
  WrappedPlacesAutocompleteInputViewProps
> = (
  {
    onSelectPlace,
    onChange,
    isInvalid,
    isRequired,
    value = "",
    request,
    renderItem = (item) => (
      <Box p={3}>
        <VStack align="stretch" gap={0.5}>
          <Text>{item.title}</Text>
          <Text color="gray.500" fontSize="sm">
            {item.subtitle}
          </Text>
        </VStack>
      </Box>
    ),
    ...props
  },
  ref,
) => {
  const [ready, { on, off }] = useBoolean(false);
  const [autocompleteService, setAutocompleteService] =
    useState<AutocompleteService>();
  const [placesService, setPlacesService] = useState<PlacesService>();
  const [suggestions, setSuggestions] = useState<AutocompleteSuggestion[]>([]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    async (e) => {
      onChange?.(e);
      if (ready && autocompleteService && e.target.value) {
        const { predictions } = await autocompleteService.getPlacePredictions({
          input: e.target.value,
          ...getDefaultSearchOptions(),
          ...request,
        });

        setSuggestions(
          predictions.map((prediction) => ({
            title: prediction.structured_formatting.main_text,
            subtitle: prediction.structured_formatting.secondary_text,
            id: prediction.place_id,
          })),
        );
      }
    },
    [ready, autocompleteService],
  );

  const handleSelect = useCallback(
    ({
      selectedItem,
    }: UseComboboxSelectedItemChange<AutocompleteSuggestion>) => {
      if (!placesService) {
        return;
      }
      placesService.getDetails(
        {
          placeId: selectedItem.id,
          fields: ["address_components", "geometry", "place_id", "name"],
        },
        (placeResult) => {
          if (!placeResult) {
            return;
          }
          onSelectPlace?.(selectedItem, placeResult);
        },
      );
    },
    [onSelectPlace, placesService],
  );
  return (
    <>
      <Script
        onReady={() => {
          on();
          setAutocompleteService(new google.maps.places.AutocompleteService());
          setPlacesService(
            new google.maps.places.PlacesService(document.createElement("div")),
          );
        }}
        onLoadStart={off}
        src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY}&libraries=places&v=weekly`}
      />
      <SearchAutocomplete
        onSelectedItemChange={handleSelect}
        ref={ref}
        inputProps={{
          ...props,
          value,
          onChange: handleChange,
          isInvalid,
          isRequired,
        }}
        items={suggestions}
        renderItem={renderItem}
        itemToString={(item) => item?.title || ""}
      />
    </>
  );
};

export const PlacesAutocompleteInput = forwardRef(
  WrappedPlacesAutocompleteInputView,
);
