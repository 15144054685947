import { DeepPartial } from "@apollo/client/utilities";
import {
  BannerVersion,
  Banner as BannerType,
} from "@/documents/__generated__/globalTypes.codegen";
import isDefined from "@/utils/isDefined";

type Banner = {
  id: number;
  version: BannerVersion;
  frontText: string;
  link: string;
};

const getBaseVersion = (banner?: DeepPartial<BannerType>): BannerVersion => {
  const versions = banner?.versions?.filter(isDefined);
  const sortedVersions = versions?.sort(
    ({ size: s1 = 0 }, { size: s2 = 0 }) => s2 - s1,
  );

  if (sortedVersions?.length) {
    const [baseVersion] = sortedVersions;

    if (!baseVersion.imageUrl) {
      throw new Error("Banner version imageUrl is required");
    }

    if (!baseVersion.size) {
      throw new Error("Banner version size is required");
    }
    return {
      imageUrl: baseVersion.imageUrl,
      size: baseVersion.size,
    };
  }

  if (!banner?.imageUrl) {
    throw new Error("If versions do not exist, banner imageUrl is required");
  }

  return {
    imageUrl: banner.imageUrl,
    size: 1100,
  };
};

export const parseBanner = (banner: DeepPartial<BannerType>): Banner => {
  const version = getBaseVersion(banner);

  if (!banner.id) {
    throw new Error("Banner id is required");
  }

  return {
    id: banner.id,
    version,
    frontText: banner.frontText || "",
    link: banner.link || "",
  };
};
