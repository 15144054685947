"use client";
import {
  Heading,
  VStack,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import CreateUser from "@/components/SignUpForm/CreateUser";
import ConfirmPhoneNumber from "@/components/SignUpForm/ConfirmPhoneNumber";
import { useRouter } from "next/navigation";
import CreateRestaurant from "@/components/SignUpForm/CreateRestaurant";
import { FC } from "react";
import { Values } from "@/components/SignUpForm/types";

type SignUpFormProps = {
  authToken?: string;
};

import { makeVar as makeLove } from "@apollo/client";

export const signUpCelebrationReactiveVar = makeLove<boolean>(false);

const SignUpForm: FC<SignUpFormProps> = ({ authToken }) => {
  const { push } = useRouter();

  const { activeStep, setActiveStep } = useSteps({
    index: authToken ? 2 : 0,
    count: 3,
  });
  const methods = useForm<Values>({
    defaultValues: {
      complete: authToken
        ? {
            user: true,
            pin: true,
            restaurant: false,
          }
        : {
            user: false,
            pin: false,
            restaurant: false,
          },
    },
  });
  const {
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const [complete, user, pin] = watch(["complete", "user", "pin"]);

  const steps = [
    {
      title: "User",
      allowed: user,
      children: (
        <CreateUser
          isComplete={complete.user}
          onSuccess={() => {
            setActiveStep(1);
            setValue("complete.user", true);
          }}
        />
      ),
    },
    {
      title: "Verification",
      allowed: pin,
      children: (
        <ConfirmPhoneNumber
          isComplete={complete.pin}
          onSuccess={(data) => {
            if (data.restaurants.length) {
              signUpCelebrationReactiveVar(true);
              push("/");
              return;
            }
            setActiveStep(2);
            setValue("complete.pin", true);
          }}
        />
      ),
    },
    {
      title: "Restaurant",
      allowed: complete.pin,
      children: (
        <CreateRestaurant
          isComplete={complete.restaurant}
          onSuccess={() => {
            setValue("complete.restaurant", true);
            signUpCelebrationReactiveVar(true);
            push("/");
          }}
        />
      ),
    },
  ];

  return (
    <FormProvider {...methods}>
      <VStack align="stretch" spacing={4}>
        <Heading size="md">Sign up</Heading>
        <Stepper index={activeStep}>
          {steps.map((step, index) => {
            return (
              <Step
                key={index}
                as={Button}
                isDisabled={!step.allowed}
                variant="unstyled"
                onClick={() => setActiveStep(index)}
              >
                <StepIndicator>
                  <StepStatus
                    complete={<StepIcon />}
                    incomplete={<StepNumber />}
                    active={<StepNumber />}
                  />
                </StepIndicator>

                <Box flexShrink="0">
                  <StepTitle>{step.title}</StepTitle>
                </Box>

                <StepSeparator />
              </Step>
            );
          })}
        </Stepper>
        {errors.root?.serverError && (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>{errors.root?.serverError?.message}</AlertTitle>
          </Alert>
        )}
        {steps[activeStep].children}
      </VStack>
    </FormProvider>
  );
};

export default SignUpForm;
